.admin-wrapper {
  display: flex;
}

.admin-content {
  width: 100%;
  background-color: #dfe0ea;
}

.admin-servey-dashboard {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.admin-drawer-header {
  margin: auto;
}

.admin-survey-questions-card-wrapper {
  margin-bottom: 10px;
}

.admin-survey-questions-add-new {
  float: right;
}

.admin-survey-questions-list {
  width: 100%;
  display: inline-block;
}

.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

#backButton {
  display: flex;
}
//React cron generator css
.cron_builder_bordering {
  border: 1px solid #ddd;
  border-top: none;
  text-align: center;
  padding: 10px;
}
.cron_builder_bordering input {
  width: 100px;
  margin-right: 10px;
  margin-left: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
  padding-left: 5px;
  cursor: pointer;
}
.cron-builder-bg {
  background-color: #086090;
  color: white;
  text-align: center;
  margin-bottom: 4px;
}
.cron_builder_bordering select {
  background-color: white;
  width: 75px;
  cursor: pointer;
  padding: 4px 0px;
  border-radius: 4px;
}
.cron_builder_bordering select option:hover {
  background-color: #086090;
}
.well-small input {
  width: auto !important;
}
.cron_builder_bordering input[type="radio"] {
  margin-top: 0px;
  vertical-align: middle;
}
.cron_builder {
  border: 1px solid #d0cbcb;
  padding: 5px;
  background-color: #dddef13d;
  width: 50%;
}
.text_align_left {
  text-align: left;
}
.nav li {
  cursor: pointer;
}

#messageButton {
  width: 100%;
}

.vl {
  border-left: 3px solid rgb(0, 0, 0);
  height: 30px;
}

.inner {
  display: inline-block;
}

.outer {
  width: 100%;
  text-align: center;
}

#messageId {
  text-align: center;
  height: 55px;
  background-color: white;
  padding: 10px;
  border-radius: 0.25em;
}

//Message Queue CSS

//1. Index.js CSS
.messenger {
  display: grid;
  width: 100%;
  height: 100vh;
  background: #eeeef1;

  grid-template-columns: 350px auto;
  grid-template-rows: 60px auto 60px;
  grid-column-gap: 1px;
  grid-row-gap: 1px;
}

.container {
  padding: 10px;
}

.scrollable {
  position: relative;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.sidebar {
  background: white;
  grid-row-start: 1;
  grid-row-end: span 3;
}

.content {
  background: white;
  grid-row-start: 1;
  grid-row-end: span 3;
}

.footer {
  grid-column-start: 2;
  background: white;
}

//2. MessageListItem.js CSS
.conversation-list-item {
  display: flex;
  align-items: center;
  padding: 10px;
}

.conversation-list-item:hover {
  background: #eeeef1;
  cursor: pointer;
}

.conversation-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.conversation-title {
  font-size: 14px;
  font-weight: bold;
  text-transform: capitalize;
  margin: 0;
}

.conversation-snippet {
  font-size: 14px;
  color: #888;
  margin: 0;
}

//3. ConversationList.js CSS
.conversation-list {
  display: flex;
  flex-direction: column;
}

.dashboard-body {
  max-width: 1600px;
  background-color: #ffffff;
}

.vertical {
  border-left: 5px solid black;
  border-width: thin;
}

.dashboard-cards {
  padding-left: 100px;
  padding-right: 100px;
}

//Cards Styling
.card {
  min-width: 150px;
  padding-right: 20px;
  padding-left: 20px;
}

.title {
  text-align: "center";
  size: "200px";
  background-color: "red";
}

.pos {
  margin-bottom: 12;
  font-weight: "bolder";
  text-align: "center";
  font-size: 50;
}

.size-chart {
  padding-top: 20px;
  text-align: center;
}

.chart-container {
  width: 100%;
  height: 100%;
}

.download-progress {
  padding-top: 18px;
}

//Message Status button colors
// .failedClass {
//   background-color: green;
// }
// .initializedClass {
//   background-color: ;
// }
// .newClass {
//   background-color: ;
// }
// .readyClass {
//   background-color: ;
// }
// .inProcessClass {
//   background-color: ;
// }
// .errorClass {
//   background-color: ;
// }
.processedClass {
  background-color: green;
}

// Initialized Color  ================

.Initilaized {
  background-color: #7ba5e8;
}

// Message Status Timeline ====================================

.vertical-timeline * {
  box-sizing: border-box;
}
.vertical-timeline {
  width: 95%;
  max-width: 1170px;
  margin: 0 auto;
  position: relative;
  padding: 2em 0;
}
.vertical-timeline::after {
  content: "";
  display: table;
  clear: both;
}
.vertical-timeline::before {
  content: "";
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: #fff;
}
@media only screen and (min-width: 1170px) {
  .vertical-timeline.vertical-timeline--two-columns {
    width: 90%;
  }
  .vertical-timeline.vertical-timeline--two-columns:before {
    left: 50%;
    margin-left: -2px;
  }
}
.vertical-timeline-element {
  position: relative;
  margin: 2em 0;
}
.vertical-timeline-element > div {
  min-height: 1px;
}
.vertical-timeline-element:after {
  content: "";
  display: table;
  clear: both;
}
.vertical-timeline-element:first-child {
  margin-top: 0;
}
.vertical-timeline-element:last-child {
  margin-bottom: 0;
}
@media only screen and (min-width: 1170px) {
  .vertical-timeline-element {
    margin: 4em 0;
  }
  .vertical-timeline-element:first-child {
    margin-top: 0;
  }
  .vertical-timeline-element:last-child {
    margin-bottom: 0;
  }
}
.vertical-timeline-element-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08),
    0 3px 0 4px rgba(0, 0, 0, 0.05);
}
.vertical-timeline-element-icon svg {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -12px;
}
@media only screen and (min-width: 1170px) {
  .vertical-timeline--two-columns .vertical-timeline-element-icon {
    width: 60px;
    height: 60px;
    left: 50%;
    margin-left: -30px;
  }
}
.vertical-timeline-element-icon {
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
}
.vertical-timeline--animate .vertical-timeline-element-icon.is-hidden {
  visibility: hidden;
}
.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
  visibility: visible;
  -webkit-animation: cd-bounce-1 0.6s;
  -moz-animation: cd-bounce-1 0.6s;
  animation: cd-bounce-1 0.6s;
}
@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -moz-transform: scale(1.2);
  }
  100% {
    -moz-transform: scale(1);
  }
}
@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.vertical-timeline-element-content {
  position: relative;
  margin-left: 60px;
  background: #fff;
  border-radius: 0.25em;
  padding: 1em;
  box-shadow: 0 3px 0 #ddd;
}
.vertical-timeline-element--no-children .vertical-timeline-element-content {
  background: 0 0;
  box-shadow: none;
}
.vertical-timeline-element-content:after {
  content: "";
  display: table;
  clear: both;
}
.vertical-timeline-element-content h2 {
  color: #303e49;
}
.vertical-timeline-element-content .vertical-timeline-element-date,
.vertical-timeline-element-content p {
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: 500;
}
.vertical-timeline-element-content .vertical-timeline-element-date {
  display: inline-block;
}
.vertical-timeline-element-content p {
  margin: 1em 0 0;
  line-height: 1.6;
}
.vertical-timeline-element-title {
  margin: 0;
}
.vertical-timeline-element-subtitle {
  margin: 0;
}
.vertical-timeline-element-content .vertical-timeline-element-date {
  float: left;
  padding: 0.8em 0;
  opacity: 0.7;
}
.vertical-timeline-element-content-arrow {
  content: "";
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  border-right: 7px solid #fff;
}
.vertical-timeline-element--no-children
  .vertical-timeline-element-content-arrow {
  display: none;
}
@media only screen and (min-width: 768px) {
  .vertical-timeline-element-content h2 {
    font-size: 20px;
    font-size: 1.25rem;
  }
  .vertical-timeline-element-content p {
    font-size: 16px;
    font-size: 1rem;
  }
  .vertical-timeline-element-content .vertical-timeline-element-date {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1170px) {
  .vertical-timeline--two-columns .vertical-timeline-element-content {
    margin-left: 0;
    padding: 1.5em;
    width: 44%;
  }
  .vertical-timeline--two-columns .vertical-timeline-element-content-arrow {
    top: 24px;
    left: 100%;
    transform: rotate(180deg);
  }
  .vertical-timeline--two-columns
    .vertical-timeline-element-content
    .vertical-timeline-element-date {
    position: absolute;
    width: 100%;
    left: 124%;
    top: 6px;
    font-size: 16px;
    font-size: 1rem;
  }
  .vertical-timeline--two-columns
    .vertical-timeline-element.vertical-timeline-element--right
    .vertical-timeline-element-content,
  .vertical-timeline--two-columns
    .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left)
    .vertical-timeline-element-content {
    float: right;
  }
  .vertical-timeline--two-columns
    .vertical-timeline-element.vertical-timeline-element--right
    .vertical-timeline-element-content-arrow,
  .vertical-timeline--two-columns
    .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left)
    .vertical-timeline-element-content-arrow {
    top: 24px;
    left: auto;
    right: 100%;
    transform: rotate(0);
  }
  .vertical-timeline--two-columns
    .vertical-timeline-element.vertical-timeline-element--right
    .vertical-timeline-element-content
    .vertical-timeline-element-date,
  .vertical-timeline--two-columns
    .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left)
    .vertical-timeline-element-content
    .vertical-timeline-element-date {
    left: auto;
    right: 124%;
    text-align: right;
  }
}
.vertical-timeline--animate .vertical-timeline-element-content.is-hidden {
  visibility: hidden;
}
.vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
  visibility: visible;
  -webkit-animation: cd-bounce-2 0.6s;
  -moz-animation: cd-bounce-2 0.6s;
  animation: cd-bounce-2 0.6s;
}
@media only screen and (min-width: 1170px) {
  .vertical-timeline--two-columns.vertical-timeline--animate
    .vertical-timeline-element.vertical-timeline-element--right
    .vertical-timeline-element-content.bounce-in,
  .vertical-timeline--two-columns.vertical-timeline--animate
    .vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left)
    .vertical-timeline-element-content.bounce-in {
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    -moz-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
  }
}
@media only screen and (max-width: 1169px) {
  .vertical-timeline--animate .vertical-timeline-element-content.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    -moz-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
  }
}
@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(20px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -moz-transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(-20px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

//Message Queue UI Fonts
.normalfont {
  font-weight: normal !important;
  font-size: 0.875rem !important;
}

//=========React Notifications CSS ===========//
@font-face {
  font-family: "Notification";
  src: url("./fonts/notification.eot?s3g3t9");
  src: url("./fonts/notification.eot?#iefixs3g3t9") format("embedded-opentype"),
    url("./fonts/notification.woff?s3g3t9") format("woff"),
    url("./fonts/notification.ttf?s3g3t9") format("truetype"),
    url("./fonts/notification.svg?s3g3t9#notification") format("svg");
  font-weight: normal;
  font-style: normal;
}

.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999;
  width: 320px;
  padding: 0px 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}

.notification {
  box-sizing: border-box;
  padding: 15px 15px 15px 58px;
  border-radius: 2px;
  color: #fff;
  background-color: #ccc;
  box-shadow: 0 0 12px #999;
  cursor: pointer;
  font-size: 1em;
  line-height: 1.2em;
  position: relative;
  opacity: 0.9;
  margin-top: 15px;
}

.notification .title {
  font-size: 1em;
  line-height: 1.2em;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.notification:hover,
.notification:focus {
  opacity: 1;
}

.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s;
}

.notification-leave {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.notification-leave.notification-leave-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s;
}

.notification:before {
  position: absolute;
  top: 50%;
  left: 15px;
  margin-top: -14px;
  display: block;
  font-family: "Notification";
  width: 28px;
  height: 28px;
  font-size: 28px;
  text-align: center;
  line-height: 28px;
}

.notification-info {
  background-color: #2f96b4;
}

.notification-info:before {
  content: "";
}

.notification-success {
  background-color: #51a351;
}

.notification-success:before {
  content: "";
}

.notification-warning {
  background-color: #f89406;
}

.notification-warning:before {
  content: "";
}

.notification-error {
  background-color: #bd362f;
}

.notification-error:before {
  content: "";
}
#responsive-dialog-title {
  background-color: #3f51b5;
  color: white;
}
.admin-content {
  background-color: white;
}
.heading_graph {
  font-size: 16px;
  font-style: bold;
  font-weight: 410;
  text-align: center;
  color: white;
}
.heading_color {
  background-color: #3f51b5;
  padding-bottom: 15px;
  margin-bottom: 30px;
}
.number-item {
  background-color: #edf0f3;
}
.active {
  background-color: #edf0f3;
}
.page-item {
  background-color: #edf0f3;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #edf0f3;
  border-color: #007bff;
}
.cron-builder__tab {
  background-color: #3f51b5 !important;
  border: 1px solid white !important;
  border-radius: 8px !important;
  color: white !important;
  display: inline-block !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  font-style: normal !important;
  padding: 5px 7px !important;
}

.cron-builder__tab--active {
  background-color: white !important;
  border: 1px solid #3f51b5 !important;
  border-radius: 8px !important;
  color: #3f51b5 !important;
  cursor: default !important;
}
.cron-builder__legend {
  padding: 0 5px !important;
  display: inline-flex !important;
}
.cron-builder__action {
  color: #3f51b5;
}
.cron-builder__pretty-expression {
  color: #3f51b5;
}

div#overidePaperElevation.MuiPaper-elevation1 {
    box-shadow: none !important;
}


// No Match Found
.mainbox {
  background-color: #95c2de;
  margin: auto;
  height: 600px;
  width: 600px;
  position: relative;
}

  .err {
    color: #ffffff;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 11rem;
    position:absolute;
    left: 20%;
    top: 8%;
  }

.far {
  position: absolute;
  font-size: 8.5rem;
  left: 42%;
  top: 15%;
  color: #ffffff;
}

 .err2 {
    color: #ffffff;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 11rem;
    position:absolute;
    left: 68%;
    top: 8%;
  }

.msg {
    text-align: center;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1.6rem;
    position:absolute;
    left: 16%;
    top: 45%;
    width: 75%;
  }

#homelink {
  text-decoration: none;
  color: white;
}

#homelink:hover {
  text-decoration: underline;
}