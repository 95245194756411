.connection {
  background-color: #fff;
  min-height: 90vh;
  padding-left: 50px;
  padding-right: 50px;
}

.button_container {
  padding-top: 30px;
  justify-content: center;
  margin-bottom: 30px;
  text-align: right;
}
.button_add {
  padding: 10px;
  min-width: 350px;
  font-size: 20px;
  background-color: rgb(63, 81, 181);
  font-weight: bold;
  justify-content: center;
}
