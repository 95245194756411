.column_container {
  height: 400px;
  overflow-y: scroll;
  border-radius: 4px;
  min-width: 99%;
}

::-webkit-scrollbar {
  width: 4px;
}

.main_container {
  margin-top: 36px;
  border: 1px solid #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #032a9c;
  border-radius: 10px;
}

.heading_col {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  padding: 15px;
  background-color: rgb(119, 136, 153);
  color: #fff;
}
