.endpoints {
  background-color: #fff;
  min-height: 90vh;
}

.heading {
  text-align: center;
  padding: 10px;
  color: #302b5a;
  font-size: 36px;
  font-weight: 500;
}
