
@media only screen and (min-width: 1200px) {
    .cron-builder {
        display: inline-block;
        min-height: 200px;
        border: 1px solid #c4c4c4;
        background-color: transparent;
        padding: 0.5vw 3vw;
        color: #818181;
        border-radius: 5px;
        font-size: 14px;
        box-shadow: inset 0 0 5px #c4c4c4;
    }
    /*.cron-builder__tab {
        background-color: #3f51b5!important;
        border: 1px solid #fff!important;
        !* border-radius: 8px!important; *!
        color: #000!important;
        display: inline-block!important;
        margin-left: 5px!important;
        margin-right: 5px!important;
        font-style: normal!important;
        padding: 5px 7px!important;
        font-size: 4vh;
    }*/

    .cron-builder__action, .cron-builder__pretty-expression {
        color: #fff !important;
        font-size: 17px;
        padding: 0 25px;
        border-radius: 4px;
        background: #3f51b5;
    }

    .Select-control {
        background-color: #fff;
        border-radius: 4px;
        border: 1px solid #ccc;
        color: #333;
        cursor: default;
        display: table;
        border-spacing: 0;
        border-collapse: separate;
        height: 36px;
        outline: none;
        overflow: hidden;
        position: relative;
        min-width: 130px !important;
        width: 100%;
    }


    .Select has-value is-clearable is-searchable Select--multi{
        margin-left:25px;
    }

    .cron-builder__tab--active {
        border: 1px solid #fefefe!important;
        border-radius: 4px!important;
        color: #fff!important;
        cursor: default!important;
        background: #3f51b5!important;
    }
    .cron-builder__tab {
        background: #3f51b5!important;
        border: 1px solid #fff!important;
        border-radius: 8px!important;
        color: #000!important;
        display: inline-block!important;
        margin-left: 2px!important;
        margin-right: 2px!important;
        font-style: normal!important;
        padding: 5px 7px!important;
        font-size: 1vw;
        font-weight: 400;
    }

    .cron-builder__tab {
        height: 36px;
        font-weight: 700;
        color: inherit;
        border: none;
        margin: .5px;
        background:grey;
        cursor: pointer;
        padding: 5px 7px;
    }

}
@media only screen and (min-width: 768px) {
    .cron-builder {
        display: inline-block;
        min-height: 200px;
        border: 1px solid #c4c4c4;
        background-color: transparent;
        padding: 1vw 4vw;
        color: #818181;
        border-radius: 5px;
        font-size: 14px;
        box-shadow: inset 0 0 5px #c4c4c4;
    }
    /*.cron-builder__tab {
        background-color: #3f51b5!important;
        border: 1px solid #fff!important;
        !* border-radius: 8px!important; *!
        color: #000!important;
        display: inline-block!important;
        margin-left: 5px!important;
        margin-right: 5px!important;
        font-style: normal!important;
        padding: 5px 7px!important;
        font-size: 4vh;
    }*/

    .cron-builder__action, .cron-builder__pretty-expression {
        color: #fff !important;
        font-size: 17px;
        padding: 0 25px;
        border-radius: 4px;
        background: #3f51b5;
    }

    .Select-control {
        background-color: #fff;
        border-radius: 4px;
        border: 1px solid #ccc;
        color: #333;
        cursor: default;
        display: table;
        border-spacing: 0;
        border-collapse: separate;
        height: 36px;
        outline: none;
        overflow: hidden;
        position: relative;
        min-width: 130px !important;
        width: 100%;
    }


    .Select has-value is-clearable is-searchable Select--multi{
        margin-left:25px;
    }

    .cron-builder__tab--active {
        border: 1px solid #fefefe!important;
        border-radius: 4px!important;
        color: #fff!important;
        cursor: default!important;
        background: #3f51b5!important;
    }
    .cron-builder__tab {
        background: #3f51b5!important;
        border: 1px solid #fff!important;
        border-radius: 8px!important;
        color: #000!important;
        display: inline-block!important;
        margin-left: 2px!important;
        margin-right: 2px!important;
        font-style: normal!important;
        padding: 5px 7px!important;
        font-size: 1vw;
        font-weight: 400;
    }

    .cron-builder__tab {
        height: 36px;
        font-weight: 700;
        color: inherit;
        border: none;
        margin: .5px;
        background:grey;
        cursor: pointer;
        padding: 5px 7px;
    }

}
@media only screen and (min-width: 992px) {
    .cron-builder {
        display: inline-block;
        min-height: 200px;
        border: 1px solid #c4c4c4;
        background-color: transparent;
        padding: 1vw 4vw;
        color: #818181;
        border-radius: 5px;
        font-size: 14px;
        box-shadow: inset 0 0 5px #c4c4c4;
    }
    /*.cron-builder__tab {
        background-color: #3f51b5!important;
        border: 1px solid #fff!important;
        !* border-radius: 8px!important; *!
        color: #000!important;
        display: inline-block!important;
        margin-left: 5px!important;
        margin-right: 5px!important;
        font-style: normal!important;
        padding: 5px 7px!important;
        font-size: 4vh;
    }*/

    .cron-builder__action, .cron-builder__pretty-expression {
        color: #fff !important;
        font-size: 17px;
        padding: 0 25px;
        border-radius: 4px;
        background: #3f51b5;
    }

    .Select-control {
        background-color: #fff;
        border-radius: 4px;
        border: 1px solid #ccc;
        color: #333;
        cursor: default;
        display: table;
        border-spacing: 0;
        border-collapse: separate;
        height: 36px;
        outline: none;
        overflow: hidden;
        position: relative;
        min-width: 130px !important;
        width: 100%;
    }


    .Select has-value is-clearable is-searchable Select--multi{
        margin-left:25px;
    }

    .cron-builder__tab--active {
        border: 1px solid #fefefe!important;
        border-radius: 4px!important;
        color: #fff!important;
        cursor: default!important;
        background: #3f51b5!important;
    }
    .cron-builder__tab {
        background: #3f51b5!important;
        border: 1px solid #fff!important;
        border-radius: 8px!important;
        color: #000!important;
        display: inline-block!important;
        margin-left: 2px!important;
        margin-right: 2px!important;
        font-style: normal!important;
        padding: 5px 7px!important;
        font-size: 1vw;
        font-weight: 400;
    }

    .cron-builder__tab {
        height: 36px;
        font-weight: 700;
        color: inherit;
        border: none;
        margin: .5px;
        background:grey;
        cursor: pointer;
        padding: 5px 7px;
    }

}

.cron-builder {
    display: inline-block;
    min-height: 200px;
    border: 1px solid #c4c4c4;
    background-color: transparent;
    padding: 1vw 4vw;
    color: #818181;
    border-radius: 5px;
    font-size: 14px;
    box-shadow: inset 0 0 5px #c4c4c4;
}
/*.cron-builder__tab {
    background-color: #3f51b5!important;
    border: 1px solid #fff!important;
    !* border-radius: 8px!important; *!
    color: #000!important;
    display: inline-block!important;
    margin-left: 5px!important;
    margin-right: 5px!important;
    font-style: normal!important;
    padding: 5px 7px!important;
    font-size: 4vh;
}*/

.cron-builder__action, .cron-builder__pretty-expression {
    color: #fff !important;
    font-size: 17px;
    padding: 0 25px;
    border-radius: 4px;
    background: #3f51b5;
}

.Select-control {
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #ccc;
    color: #333;
    cursor: default;
    display: table;
    border-spacing: 0;
    border-collapse: separate;
    height: 36px;
    outline: none;
    overflow: hidden;
    position: relative;
    min-width: 130px !important;
    width: 100%;
}


.Select has-value is-clearable is-searchable Select--multi{
    margin-left:25px;
}

.cron-builder__tab--active {
    border: 1px solid #fefefe!important;
    border-radius: 4px!important;
    color: #fff!important;
    cursor: default!important;
    background: #3f51b5!important;
}
.cron-builder__tab {
    background: #3f51b5!important;
    border: 1px solid #fff!important;
    border-radius: 8px!important;
    color: #000!important;
    display: inline-block!important;
    margin-left: 2px!important;
    margin-right: 2px!important;
    font-style: normal!important;
    padding: 5px 7px!important;
    font-size: 1vw;
    font-weight: 400;
}

.cron-builder__tab {
    height: 36px;
    font-weight: 700;
    color: inherit;
    border: none;
    margin: .5px;
    background:grey;
    cursor: pointer;
    padding: 5px 7px;
}
